import { useState, useEffect } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'

import { Container } from '@mui/system'
import Grid from '@mui/material/Unstable_Grid2'
import MainContainer from '../../components/Layout/MainContainer/MainContainer'
import { Box, Button, Typography } from '@mui/material'
import sadHemphrey from './sad-hemphrey.png'

import FilledInput from '@mui/material/FilledInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

import { Link } from 'react-router-dom'

import {
   getAuth,
   signInWithEmailLink,
   isSignInWithEmailLink,
   getAdditionalUserInfo
} from "firebase/auth"

export default function Welcome() {
   const auth = getAuth()
   const { dispatch } = useAuthContext()
   
   const styles = {
      actionBox: {
         backgroundColor: '#ffffff',
         padding: 3,
         borderRadius: '7px'
      },
      hempfrey: {
         maxWidth: '250px',
      }
   }

   const [ mode, setMode ] = useState( 'newUser' )
   const [ error, setError ] = useState( '' )
   const [ email, setEmail ] = useState( window.localStorage.getItem('emailForSignIn') || "" )
   const [ formError, setFormError ] = useState( null )

   const handleVerifyEmailSubmit = ( e ) => {
      e.preventDefault()
   
      if ( !email ) {
         setFormError( 'Please enter a valid email' ) // Not working!!! Fuck the whole fucking world!!!!
      } else {
         setFormError( '' )
         setEmail( email )
      }

      console.log( 'formError', formError )
   }

   useEffect( () => {
      if ( isSignInWithEmailLink( auth, window.location.href ) ) {
         // Additional state parameters can also be passed via URL.
         // This can be used to continue the user's intended action before triggering
         // the sign-in operatiosn.
         // Get the email if available. This should be available if the user completes
         // the flow on the same device where they started it.
         // let email = window.localStorage.getItem('emailForSignIn')
         
         if ( !email ) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            //email = window.prompt('Please provide your email for confirmation')
            setMode( 'verifyEmail' )
         } else {
            // The client SDK will parse the code from the link for you.
            signInWithEmailLink( auth, email, window.location.href )
               .then( ( res ) => {
                  const { isNewUser } = getAdditionalUserInfo( res )
                  const user = res.user
                  setMode( isNewUser ? 'newUser' : 'currentUser' )
                  
                  // Clear email from storage.
                  window.localStorage.removeItem( 'emailForSignIn' )
                  // You can access the new user by importing getAdditionalUserInfo
                  // and calling it with result:
                  // getAdditionalUserInfo(result)
                  // You can access the user's profile via:
                  // getAdditionalUserInfo(result)?.profile
                  // You can check if the user is new or existing:
                  // getAdditionalUserInfo(result)?.isNewUser
      
                  dispatch( { type: 'LOGIN', payload: res.user } )

                  // If they are a current user, Show them an You're all set page with a "take me back to what I was doing" message.
                  // Maybe also show them a "What's new" message.
                  // If they are a new user, walk them through a quick onboarding process.
                  // Be considerate of the user journey up to this point. Maybe create two versions, a 101 version and a 201 version.
                  // Explain how they can use filters, save favorites and how the daily drop email works.
                  // Expand on the daily drop idea by introducing the Hempstok Reflector
               } )
               .catch( ( error ) => {
               // Some error occurred, you can inspect the code: error.code
               // Common errors could be invalid email and invalid or expired OTPs.
                  setError( `Bad Link: ${ error.code }` )
                  setMode( 'error' )
               }
            )
         }
      } else {
         setError( `No Link: ${ error.code }` )
         setMode( 'error' )
      }
   }, [ formError ] )

   if ( mode === 'verifyEmail' ) {

      return (
         <MainContainer fullWidth={ true }>
            <Box pt={2} sx={ { backgroundColor: "grey.main", height: "100%", paddingTop: { xs: 2, md: 10 } } }>
               <Container maxWidth="md" sx={ { backgroundColor: '#e3e4dd' } }>
                  <Box sx={ { padding: "48px 14px" } }>
                     <Grid container spacing={ 2 } sx={ { justifyContent: 'center', alignItems: 'center' } }>
                        <Grid sm={ 6 } sx={ { textAlign: 'center' } }>
                           <img style={ styles.hempfrey } src="https://firebasestorage.googleapis.com/v0/b/hempstokapp.appspot.com/o/hempstok%20logos%2Fhempstok-logo-circle.png?alt=media&token=a4136088-2eb7-4214-89bf-e3d8655f6ab2" alt="" width="100%" />
                        </Grid>
                        <Grid sm={ 6 } >
                           <Typography variant="h2" gutterBottom>Please Confirm Email:</Typography>
                           <Box pb="2rem">
                              <Box sx={ styles.actionBox }>
                                 <form onSubmit={ handleVerifyEmailSubmit }>
                                    <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
                                       <InputLabel htmlFor="email">Email address</InputLabel>
                                       <FilledInput
                                          id="email"
                                          onChange={ ( e ) => setEmail( e.target.value ) }
                                          value={ email }
                                          autoComplete="email"
                                          type="email"
                                          required={ true }
                                          autoFocus={ true }
                                          disableUnderline={ true }
                                       />
                                    </FormControl>
                                    { formError && <Typography mt={ 2 } color="red.main">{ formError }</Typography> }
                                    <Button variant="contained" size="medium" type="submit">
                                       Confirm Email
                                    </Button>
                                 </form>
                              </Box>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
               </Container>
            </Box>      
         </MainContainer>
      )
   }
 
   if ( mode === 'error' ) {
      return (
         <MainContainer fullWidth={ true }>
            <Box pt={ 2 } sx={ { backgroundColor: "grey.main", height: "100%", paddingTop: { xs: 2, md: 10 } } }>
               <Container maxWidth="md" sx={ { backgroundColor: '#e3e4dd' } }>
                  <Box sx={ { padding: "48px 14px" } }>
                     <Grid container spacing={ 2 } sx={ { justifyContent: 'center', alignItems: 'center' } }>
                        <Grid sm={ 6 } sx={ { textAlign: 'center' } }>
                           <img style={ styles.hempfrey } src={ sadHemphrey } alt="Oh, Snap!" width="100%" />
                        </Grid>
                        <Grid sm={ 6 } >
                           <Typography variant="h1" gutterBottom>Oh, snap! The system is trippin.</Typography>
                           <Box pb="2rem">Here's Whassup: { error }</Box>

                           <Typography variant="h2" gutterBottom>What to do next?</Typography>
                           <Box sx={ styles.actionBox }>
                              <Typography variant="body1" gutterBottom>1. Try <Link to="/login">logging in</Link> again.</Typography>
                              <Typography variant="body1" gutterBottom>2. <a href="mailto:support@hempstok.com" rel="noreferrer" target="_blank">Contact support</a> if the problem persists.</Typography>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
               </Container>
            </Box>      
         </MainContainer>
      )
   }
   
   return (
      <MainContainer fullWidth={ true }>
         <Box pt={2} sx={ { backgroundColor: "grey.main", height: "100%", paddingTop: { xs: 2, md: 10 } } }>
            <Container maxWidth="md" sx={ { backgroundColor: '#e3e4dd' } }>
               <Box sx={ { padding: "48px 14px" } }>
                  <Grid container spacing={ 2 } sx={ { justifyContent: 'center', alignItems: 'center' } }>
                     <Grid sm={ 6 } sx={ { textAlign: 'center' } }>
                        <img style={ styles.hempfrey } src="https://firebasestorage.googleapis.com/v0/b/hempstokapp.appspot.com/o/hempstok%20logos%2Fhempstok-logo-circle.png?alt=media&token=a4136088-2eb7-4214-89bf-e3d8655f6ab2" alt="Hemphrey" width="100%" />
                     </Grid>
                     <Grid sm={ 6 } >
                        { /* New User */ }
                        { mode === 'newUser' && (
                           <>
                              <Typography variant="h1" gutterBottom>Welcome to Hempstok!</Typography>
                              <Typography variant="h2" gutterBottom>You're all set! You now have access to exclusive promotions and discounts.</Typography>
                           </>
                        ) }
                        { /* Current User Logging back in */ }
                        { mode === 'currentUser' && (
                           <>
                              <Typography variant="h1" gutterBottom>Welcome Back to Hempstok!</Typography>
                           </>
                        ) }
                        <Box sx={ { textAlign: 'center', marginTop: '2rem' } }>
                           <Button variant="contained" size="medium" component={ Link } to="/">
                              Browse CBD Strains
                           </Button>
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Box>      
      </MainContainer>
   )
}
