import { createContext, useEffect } from 'react'
import { useState } from 'react'

import defaultCoupons from '../coupons/defaultCoupons'
import shabazzCoupons from '../coupons/shabazzCoupons'
import shabazzStokHeader from '../pages/shabazz/header-basic.jpg'

export const PageContext = createContext()

let headerBG = ""
let headerBGColor = ""
let coupons = ""
let linkExt = ""

export const PageContextProvider = ( { children } ) => {
   const [ channel, setChannel ] = useState( localStorage.getItem( 'channel' ) )

   switch ( channel ) {
      case 'shabazzstok':
         headerBGColor = '#000000'
         headerBG = shabazzStokHeader
         coupons = shabazzCoupons
         break
      default:
         coupons = defaultCoupons
   }

   const toolbarHeight = 65

  return (
    <PageContext.Provider value={ {
      channel: channel,
      headerBGColor: headerBGColor,
      headerBG: headerBG,
      coupons: coupons,
      
      scrollMarginTop: channel ? toolbarHeight * 2 : toolbarHeight,
      toolbarHeight: toolbarHeight,
      setChannel: setChannel
   } }>
      { children }
    </PageContext.Provider>
  )
}