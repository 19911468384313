import { useState, useEffect } from "react"

import { Box, Typography } from '@mui/material'
import { db } from '../../firebase/config'

import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Unstable_Grid2'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Checkbox from '@mui/material/Checkbox'

import { collection, onSnapshot, query, where, getDocs } from 'firebase/firestore'

import {
   DataGrid,
   GridActionsCellItem,
   GridToolbarContainer,
   GridToolbarFilterButton
} from '@mui/x-data-grid'

import { useCollection } from '../../hooks/useCollection'

import { useDocument } from '../../hooks/useDocument'

import { doc, updateDoc } from 'firebase/firestore'

import Button from '@mui/material/Button'

import TextEditor from './TextEditor'

export default function ProductModal( { open, productId, vendor, handleModalClose } ) {   
   
   const [ photo, setPhoto ] = useState('')
   const [ hempType, setHempType ] = useState('')
   const [ description, setDescription ] = useState('')
   const [ effect, setEffect ] = useState('')
   const [ enviro, setEnviro ] = useState('')
   const [ shabazzReview, setShabazzReview ] = useState('')
   const [ ssoa, setSsoa] = useState('')
   const [ inputFields, setInputFields ] = useState([
      { key: '', value: '' }
   ])
   const [ queue, setQueue ] = useState( '' )
   const [ logEntries, setLogEntries ] = useState('')
   const [ HH24, setHH24 ] = useState( '' )

   const { document: productDoc } = useDocument( `vendors/${ vendor }/products`, productId )

   useEffect( () => {      
      if ( productDoc ) {
         const fetchLogEntries = async () => {

            // Get the update log for this product
            const q = query(
               collection( db, 'vendorUpdates' ),
               where( 'vendor_slug', '==', vendor ),
               where( 'pid', '==', productId )
            )

            // Getting called twice for some reason
            const querySnapshot = await getDocs( q )
      
            const results = []

            querySnapshot.forEach( ( doc ) => {
               results.push( { id: doc.id, ...doc.data() } )
            } )

            setLogEntries( results )
         }

         fetchLogEntries()

         setPhoto( productDoc.thumb_src || '' )
         setHempType( productDoc.hemp_type || '' )
         setDescription( productDoc.description || '' )
         setEffect( productDoc.effect || '' )
         setEnviro( productDoc.enviro || '' )
         setShabazzReview( productDoc.shabazzreview || '' )
         setSsoa( productDoc.ssoa || '' )
         setInputFields( [] )
         setQueue( productDoc.queue || '' )
         setHH24( productDoc.hh24 || '' )

         const coa = []
         if ( productDoc.coa_url ) {
            for ( const [ key, value ] of Object.entries( productDoc.coa_url ) ) {
               coa.push( { key: key, value: value } )
            }

            setInputFields( coa )
         }
      }
   }, [ productId, productDoc ] )
   
   const handleSubmit = async ( e ) => {
      e.preventDefault()
      handleModalClose()
      const coaObject = {}
      inputFields.forEach( coa => {
         coaObject[ coa.key ] = coa.value
      } )

      await updateDoc( doc( db, `vendors/${ vendor }/products`, productId ), {
         thumb_src: photo,
         hemp_type: +hempType,
         coa_url: coaObject,
         description: description,
         effect: effect,
         enviro: enviro,
         shabazzreview: shabazzReview,
         ssoa: ssoa,
         queue: queue,
         hh24: HH24,
      } )
   }

   const handleFormChange = ( index, e ) => {
      let data = [ ...inputFields ]
      data[ index ][ e.target.name ] = e.target.value
      setInputFields( data )
   }

   const addFields = ( e ) => {
      e.preventDefault()
      let newField = { key: '', value: '' }

      setInputFields( [ ...inputFields, newField ] )
   }

   const removeFields = ( e, index ) => {
      e.preventDefault()
      let data = [ ...inputFields ]
      data.splice( index, 1 )
      setInputFields( data )
   }

   const styles = {
      closeBtn: {
         position: 'absolute',
         right: 8,
         top: 8,
      },
   }

   if ( !productDoc ) {
      return ''
   }

   const columns = [
      // Type
      {
         field: 'type',
         headerName: 'Update Type',
         width: 100,
      },
   
      // Update Date
      {
         field: 'time',
         headerName: 'Update Date',
         width: 175,
         type: 'dateTime',
         valueGetter: (params) => params.row.time.toDate(),
      },
   
      { field: 'new_value', headerName: 'New Value', width: 150 },
      { field: 'old_value', headerName: 'Old Value', width: 150 },
   ]
   
   return (
      <Dialog
         fullWidth
         maxWidth="md"
         onClose={ handleModalClose }
         open={ open }
         scroll="body"
      >
         <DialogTitle>
            { productDoc ? `${ productDoc.title } by ${ productDoc.vendorName }` : '' }
         </DialogTitle>
         <IconButton
            onClick={ handleModalClose }
            sx={ styles.closeBtn }
         >
            <CloseIcon />
         </IconButton>
         
         <form onSubmit={ handleSubmit }>
            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Photo:</Typography>

               <TextField
                  label="Photo"
                  variant="outlined"
                  onChange={ ( e ) => setPhoto( e.target.value ) }
                  value={ photo }
                  margin="normal"
                  fullWidth
                  size="small"
               />
            </DialogContent>
            
            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Chemotype:</Typography>
               <FormControl fullWidth margin="normal">
                  <InputLabel id="chemotype-label">Chemotype</InputLabel>
                  <Select
                     labelId="chemotype-label"
                     id="chemotype-select"
                     value={ hempType }
                     label="Chemotype"
                     onChange={ ( e ) => setHempType( e.target.value ) }
                     size="small"
                  >
                     <MenuItem value={ '1' }>Type 1: THC</MenuItem>
                     <MenuItem value={ '2' }>Type 2: THC + CBD</MenuItem>
                     <MenuItem value={ '3' }>Type 3: CBD</MenuItem>
                     <MenuItem value={ '4' }>Type 4: CBG</MenuItem>
                  </Select>
               </FormControl>
            </DialogContent>

            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">COA Links:</Typography>
               { inputFields && inputFields.map( ( input, index ) => {
                  return (
                     <Grid
                        container
                        spacing={ 3 }
                        alignItems="center"
                        key={ index }
                     >
                        <Grid xs={ 5 } >
                           <TextField
                              label="key"
                              value={ input.key }
                              onChange={ e => handleFormChange( index, e ) }
                              name="key"
                              fullWidth
                              size="small"
                           />
                        </Grid>
                        <Grid xs={ 5 } >
                           <TextField
                              label="value"
                              value={ input.value }
                              onChange={ e => handleFormChange( index, e ) }
                              name="value"
                              fullWidth
                              size="small"
                           />
                        </Grid>
                        <Grid xs={ 2 }>
                           <IconButton
                              aria-label="remove"
                              variant="outlined"
                              onClick={ ( e ) => removeFields( e, index ) }
                              size="small"
                              color="pink"
                           >
                              <DeleteOutlineIcon />
                           </IconButton>
                        </Grid>
                     </Grid>
                  )
               } ) }

               <Button
                  variant="text"
                  onClick={ addFields }
               >
                  Add Field
               </Button>
            </DialogContent>

            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Description:</Typography>
               <TextEditor content={ productDoc.description } setDescription={ setDescription } />
            </DialogContent>

            <DialogContent dividers>
            <Typography gutterBottom variant="h6" color="secondary">Effect:</Typography>
               <FormControl fullWidth margin="normal">
                  <InputLabel>Effect</InputLabel>
                  <Select
                     value={ effect }
                     label="Effect"
                     onChange={ ( e ) => setEffect( e.target.value ) }
                     size="small"
                  >
                     <MenuItem value={ 'indica' }>indica</MenuItem>
                     <MenuItem value={ 'indica-leaning' }>indica-leaning</MenuItem>
                     <MenuItem value={ 'hybrid' }>hybrid</MenuItem>
                     <MenuItem value={ 'sativa-leaning' }>sativa-leaning</MenuItem>
                     <MenuItem value={ 'sativa' }>sativa</MenuItem>
                  </Select>
               </FormControl>
            </DialogContent>

            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Cultivation Environment:</Typography>
               <FormControl fullWidth margin="normal">
                  <InputLabel>Cultivation Environment</InputLabel>
                  <Select
                     value={ enviro }
                     label="Environmen"
                     onChange={ ( e ) => setEnviro( e.target.value ) }
                     size="small"
                  >
                     <MenuItem value={ 'outdoor' }>outdoor</MenuItem>
                     <MenuItem value={ 'greenhouse' }>greenhouse</MenuItem>
                     <MenuItem value={ 'indoor' }>indoor</MenuItem>
                  </Select>
               </FormControl>
            </DialogContent>

            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Queue:</Typography>

               <TextField
                  label="Queue"
                  variant="outlined"
                  onChange={ ( e ) => setQueue( e.target.value ) }
                  value={ queue }
                  margin="normal"
                  fullWidth
                  size="small"
               />
            </DialogContent>

            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Shabazz Review:</Typography>

               <TextField
                  label="Shabazz Review URL"
                  variant="outlined"
                  onChange={ ( e ) => setShabazzReview( e.target.value ) }
                  value={ shabazzReview }
                  margin="normal"
                  fullWidth
                  size="small"
               />

               <Checkbox
                  checked={ ssoa || false }
                  onChange={ ( e ) => setSsoa( e.target.checked ) }
               />
               Shabazz Seal of Approval
            </DialogContent>

            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Other Settings:</Typography>

               <Checkbox
                  checked={ HH24 || false }
                  onChange={ ( e ) => setHH24( e.target.checked ) }
               />
               HH24
            </DialogContent>
         
            <DialogContent dividers>
               <Typography gutterBottom variant="h6" color="secondary">Vendor Updates:</Typography>

               { logEntries && (
               <DataGrid
                  rows={ logEntries }
                  columns={ columns }
                  initialState={{
                     pagination: {
                        paginationModel: { page: 0, pageSize: 100 },
                     },
                     sorting: {
                        sortModel: [{ field: 'time', sort: 'desc' }],
                     },
                  }}
                  pageSizeOptions={ [ 100 ] }
               />
               ) }
            </DialogContent>

            <DialogActions>
               <Button variant="contained" type="submit">Update</Button>
               <Button
                  variant="outlined"
                  onClick={ ( e ) => {
                     e.preventDefault()
                     handleModalClose()
                  } }
                  ml={ 2 }
               >
                  Cancel
               </Button>
            </DialogActions>
            { /* error && <p>{ error }</p> */}
         </form>
      </Dialog>
   )
}
