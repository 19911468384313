import { useState, useEffect } from 'react'
import { useLogin } from '../hooks/useLogin'
import { useAuthContext } from '../hooks/useAuthContext'

import { Container } from '@mui/system'
import Grid from '@mui/material/Unstable_Grid2'
import MainContainer from '../components/Layout/MainContainer/MainContainer'
import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { NavLink as RouterLink } from "react-router-dom"

import IconButton from '@mui/material/IconButton'
import FilledInput from '@mui/material/FilledInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import {
   getAuth,
   sendPasswordResetEmail,
   sendSignInLinkToEmail,
} from "firebase/auth"

export default function Login() {
   const styles = {
      // Not doing anything
      input: {
         "& .MuiFilledInput-root": {
            background: "#ffffff"
         }
      },
      actionBox: {
         backgroundColor: '#ffffff',
         padding: 3,
         borderRadius: '7px'
      },
      hempfrey: {
         maxWidth: '250px',
      }
   }

   const [ email, setEmail ] = useState('')
   const [ password, setPassword ] = useState('')
   const [ showPassword, setShowPassword ] = useState( false )
   const [ formError, setFormError ] = useState( null )
   // const [ mode, setMode ] = useState( 'emailLink' )
   const [ mode, setMode ] = useState( 'password' )

   const { errorCode, login, isPending } = useLogin()

   const renderErrorMessage = ( errorCode ) => {
      switch( errorCode ) {
         case 'auth/invalid-email':
            return 'Email address is invalid'
         case 'auth/user-not-found':
            return 'No users exist with that email'
         case 'auth/wrong-password':
            return 'Wrong password'
         case null:
            return ''
         default:
            return 'Something went wrong'
      }
   }

   const auth = getAuth()

   useEffect( () => {
      setFormError( renderErrorMessage( errorCode ) )
   }, [ errorCode ] )

   const handleClickShowPassword = () => setShowPassword((show) => !show)

   const handleSubmit = ( e ) => {
      e.preventDefault()

      if ( !email || !password ) {
         setFormError('Please enter a valid email and password.')
      } else {
         login( email, password )
      }
   }

   const handlePasswordReset = () => {
      
      if ( !email ) {
         setFormError('Please enter a valid email.')
         return false
      }
      
      sendPasswordResetEmail(auth, email)
         .then(() => {
            alert('Check your email for a link to reset your password.')
         })
         .catch(( error ) => {
            setFormError( renderErrorMessage( error.code ) )
      } )
   }

   const handleLoginLinkSubmit = ( e ) => {
      e.preventDefault()

      if ( !email ) {
         setFormError('Please enter a valid email.')
         return false
      }

      sendSignInLinkToEmail( auth, email, actionCodeSettings )
         .then( () => {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            window.localStorage.setItem( 'emailForSignIn', email )
            setMode( 'pendingEmailConfirmation' )
         } )
         .catch( ( error ) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            console.log( errorCode, errorMessage )
         } )
   }

   let redirectURL = "https://hempstok.com/welcome"
   
   if ( !process.env.NODE_ENV || process.env.NODE_ENV === "development" ) {
      redirectURL = 'http://localhost:3000/welcome'
   }

   const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: redirectURL,
      // This must be true.
      handleCodeInApp: true,
   }    

   return (
      <MainContainer fullWidth={ true }>
         <Box pt={2} sx={ { backgroundColor: "grey.main", height: "100%", paddingTop: { xs: 2, md: 10 } } }>
            <Container maxWidth="md" sx={ { backgroundColor: '#e3e4dd' } }>
               <Box sx={ { padding: "48px 14px" } }>
                  <Grid container spacing={ 2 } sx={ { justifyContent: 'center', alignItems: 'center' } }>
                     <Grid sm={ 6 } sx={ { textAlign: 'center' } }>
                        <img style={ styles.hempfrey } src="https://firebasestorage.googleapis.com/v0/b/hempstokapp.appspot.com/o/hempstok%20logos%2Fhempstok-logo-circle.png?alt=media&token=a4136088-2eb7-4214-89bf-e3d8655f6ab2" alt="Hemphrey" width="100%" />
                     </Grid>
                     <Grid sm={ 6 } >
                        { /* Email Link Default */ }
                        { mode === 'emailLink' && (
                           <>
                              <Typography component="h1" variant="h4" textAlign="center" mb={ 3 }>Welcome Back!</Typography>
                              <Box sx={ styles.actionBox }>
                                 <form onSubmit={ handleLoginLinkSubmit }>
                                    <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
                                       <InputLabel htmlFor="email">Email address</InputLabel>
                                       <FilledInput
                                          id="email"
                                          onChange={ ( e ) => setEmail( e.target.value )}
                                          value={ email }
                                          autoComplete="email"
                                          type="email"
                                          required={ true }
                                          autoFocus={ true }
                                          disableUnderline={ true }
                                       />
                                    </FormControl>

                                    <Box mt={ 2 }>
                                       <Button
                                          variant='contained'
                                          type="submit"
                                       >
                                          Next
                                       </Button>
                                    </Box>

                                    { formError && <Typography mt={ 2 } color="red.main">{ formError }</Typography> }
                                 </form>
                              </Box>
                           </>
                        ) }

                        { /* Pending Email Confirmation */ }
                        { mode === 'pendingEmailConfirmation' && (
                           <>
                              <Typography component="h1" variant="h4" textAlign="center" mb={ 3 }>Check your email!</Typography>
                              <Box sx={ styles.actionBox }>
                                 <Typography sx={ { fontWeight: 'bold' } }  variant='body1' gutterBottom>
                                    We just sent an email to { email }.
                                 </Typography>
                                 <Typography gutterBottom>
                                    Check your email for a link to log in. If you don't see it, check your spam folder.
                                 </Typography>
                                 <Box mt={ 5 }>
                                    <Button
                                       variant='contained'
                                       onClick={ () => setMode( 'emailLink' ) }
                                    >
                                       Send Again
                                    </Button>
                                 </Box>
                              </Box>
                           </>
                        ) }
                     
                        { mode === 'password' && (
                           <>
                              <Box sx={ styles.actionBox }>
                                 <Typography component="h1" variant="h4" textAlign="center" mb={ 3 } gutterBottom>Sign In:</Typography>
                                 <form onSubmit={ handleSubmit }>
                                    <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
                                       <InputLabel htmlFor="email">Email address</InputLabel>
                                       <FilledInput
                                          id="email"
                                          onChange={ ( e ) => setEmail( e.target.value )}
                                          value={ email }
                                          autoComplete="email"
                                       />
                                    </FormControl>

                                    <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
                                       <InputLabel htmlFor="password">Password</InputLabel>
                                       <FilledInput
                                          id="password"
                                          type={showPassword ? 'text' : 'password'}
                                          onChange={ ( e ) => setPassword( e.target.value )}
                                          value={ password }
                                          sx={ styles.input }
                                          autoComplete="current-password"
                                          endAdornment={
                                             <InputAdornment position="end">
                                                <IconButton
                                                   aria-label="toggle password visibility"
                                                   onClick={ handleClickShowPassword }
                                                   edge="end"
                                                >
                                                   { showPassword ? <VisibilityOff /> : <Visibility /> }
                                                </IconButton>
                                             </InputAdornment>
                                          }
                                       />
                                    </FormControl>                              
                                    
                                    <Box mt={ 3 } sx={ { display: 'flex', justifyContent: 'space-between' } }>
                                       <Button
                                          variant='text'
                                          onClick={ handlePasswordReset }
                                       >
                                          Reset Password
                                       </Button>
                                       
                                       {
                                          ( isPending )
                                          ?
                                             (
                                                <Button variant="contained" size="medium" disabled>
                                                   Logging In
                                                </Button>
                                             )
                                          :
                                             (
                                                <Button variant="contained" size="medium" type="submit">
                                                   Log In!
                                                </Button>
                                             )
                                       }
                                    </Box>
                                    { formError && <Typography mt={ 2 } color="red.main">{ formError }</Typography> }
                                 </form>
                              </Box>
                              <Box>
                                 <Typography variant="h6" textAlign="center" pt={ 2 }>
                                    Don't have an account?<br />
                                    <RouterLink to="/signup">Create an account</RouterLink>
                                 </Typography>
                              </Box>
                           </>
                        ) }
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Box>      
      </MainContainer>
   )
}
