import { NavLink as RouterLink } from "react-router-dom"
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'

const FriendsModal = ( { friendsModalOpen, handleClose } ) => {

   const styles = {}
   
   return (
      <Dialog
         open={ friendsModalOpen }
         onClose={ handleClose }
         aria-labelledby="friendsModal"
         aria-describedby="friendsModalDescription"
      >
         <DialogTitle id="friendsModal">
            Join Hempstok
         </DialogTitle>
         <DialogContent>
            <DialogContentText component='div' id="friendsModalDescription">
               Get discounts, enter giveaways, and save your favorite strains. Join the Hempstok platform for free.
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button
               variant="contained"
               color="primary"
               component={ RouterLink }
               to="/signup"
               autoFocus
            >
               Join Hempstok
            </Button>
         </DialogActions>
      </Dialog>
   )
}

export default FriendsModal
