import './index.css'
import App from './App'
import { AuthContextProvider } from './context/AuthContext'
import { BrowserRouter } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import { createTheme, ThemeProvider } from '@mui/material'


import { createRoot } from 'react-dom/client';

import TagManager from 'react-gtm-module'
import { PageContextProvider } from './context/PageContext'

const tagManagerArgs = {
   gtmId: 'GTM-K73P72X8'
}

TagManager.initialize(tagManagerArgs)

// https://coolors.co/3d5125-7baa45-7798ab-40476d-ff8427

const theme = createTheme( {
   palette: {
      primary: {
         main: '#7baa45',
         contrastText: '#fff'
      },
      secondary: {
         main: '#3d5125',
         contrastText: '#fff'
      },
      darkGreen: {
         main: '#3d5125',
         contrastText: '#fff',
         dark: '#e06100',
      },
      orange: {
         main: '#ff8427',
         contrastText: '#fff',
         dark: '#e06100',
      },
      darkBlue : {
         main: '#40476D',
         contrastText: '#fff',
         dark: '#262a40',
      },
      blue: {
         main: '#7798AB',
         contrastText: '#fff',
         dark: '#4e6d7e'
      },
      black: {
         main: '#333333',
         contrastText: '#fff',
         dark: '#666666'
      },
      grey: {
         main: '#cbccbf',
         contrastText: '#fff',
         dark: '#b3b4ab'
      },
      white: {
         main: '#ffffff'
      },
      red: {
         main: '#fe251b',
         contrastText: '#fff',
         dark: '#c51104'
      },
      pink: {
         main: '#e91e63',
         contrastText: '#fff',
         dark: '#d43545'
      },
   },
  components: {
    MuiTypography: {
      variants: [
        {
          props: {
            variant: 'body2',
          },
          style: {
            fontSize: 11,
          }
        },
        {
          props: {
            variant: 'body3',
          },
          style: {
            fontSize: 9,
          }
        },
        {
          props: {
            variant: 'h1',
          },
          style: {
            fontSize: 34,
            fontWeight: 'bolder',
          }
        },
        {
          props: {
            variant: 'h2',
          },
          style: {
            fontSize: 30,
          }
        }
      ]
    }
  }
} )

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
   <AuthContextProvider>
      <BrowserRouter>
         <ThemeProvider theme={ theme }>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <PageContextProvider>
               <App tab="home" />
            </PageContextProvider>
         </ThemeProvider>
      </BrowserRouter>
   </AuthContextProvider>
);
