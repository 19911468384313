import { NavLink as RouterLink } from "react-router-dom"
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'

const WelcomeModal = ( { modalOpen, handleClose } ) => {

   const styles = {}
   
   return (
      <Dialog
         open={ modalOpen }
         onClose={ handleClose }
         aria-labelledby="WelcomeModal"
         aria-describedby="WelcomeModalDescription"
      >
         <DialogTitle>
            Read this first!
         </DialogTitle>
         <DialogContent>
            <DialogContentText component='div' id="WelcomeModalDescription">
               Thanks for check out Hempstok.
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button
               variant="contained"
               color="primary"
               component={ RouterLink }
               to="/"
               autoFocus
            >
               Start Browsing
            </Button>
         </DialogActions>
      </Dialog>
   )
}

export default WelcomeModal
